// extracted by mini-css-extract-plugin
export var article = "articleRecommendation-module--article--f3bfb";
export var article_image = "articleRecommendation-module--article_image--fb497";
export var article_imageDesktop = "articleRecommendation-module--article_image--desktop--fcf8c";
export var article_imageMobile = "articleRecommendation-module--article_image--mobile--afa62";
export var article_image__gatsby = "articleRecommendation-module--article_image__gatsby--cebbb";
export var article_link = "articleRecommendation-module--article_link--9c615";
export var article_summary = "articleRecommendation-module--article_summary--35c05";
export var article_title = "articleRecommendation-module--article_title--ac6c9";
export var awesome = "articleRecommendation-module--awesome--d0ffe";
export var bold = "articleRecommendation-module--bold--5f31d";
export var center = "articleRecommendation-module--center--2507b";
export var h1 = "articleRecommendation-module--h1--30240";
export var h1Bold = "articleRecommendation-module--h1--bold--d00f1";
export var h1Uc = "articleRecommendation-module--h1--uc--24095";
export var h2 = "articleRecommendation-module--h2--48540";
export var h2Bold = "articleRecommendation-module--h2--bold--b954f";
export var h2Uc = "articleRecommendation-module--h2--uc--721ba";
export var h3 = "articleRecommendation-module--h3--5d3d7";
export var h3Bold = "articleRecommendation-module--h3--bold--e9de3";
export var h3Uc = "articleRecommendation-module--h3--uc--47ba6";
export var h4 = "articleRecommendation-module--h4--ada53";
export var h4Bold = "articleRecommendation-module--h4--bold--ec0ef";
export var h4Uc = "articleRecommendation-module--h4--uc--64d84";
export var linkUnderline = "articleRecommendation-module--link--underline--69a28";
export var microtext = "articleRecommendation-module--microtext--2b672";
export var microtextBold = "articleRecommendation-module--microtext--bold--4edd1";
export var microtextUc = "articleRecommendation-module--microtext--uc--700ee";
export var nanotext = "articleRecommendation-module--nanotext--355de";
export var strikethrough = "articleRecommendation-module--strikethrough--f5631";
export var strikethroughBold = "articleRecommendation-module--strikethrough--bold--6cc5c";
export var strikethroughLg = "articleRecommendation-module--strikethrough--lg--f04b3";
export var strikethroughSm = "articleRecommendation-module--strikethrough--sm--35ca0";
export var subtext = "articleRecommendation-module--subtext--c3189";
export var subtextBold = "articleRecommendation-module--subtext--bold--c08ec";
export var subtextUc = "articleRecommendation-module--subtext--uc--3ec72";
export var text = "articleRecommendation-module--text--c7480";
export var textBold = "articleRecommendation-module--text--bold--10b8c";
export var textUc = "articleRecommendation-module--text--uc--e834b";
export var titleL = "articleRecommendation-module--title-l--0f185";
export var titleM = "articleRecommendation-module--title-m--49431";
export var titleS = "articleRecommendation-module--title-s--dd832";
export var titleXl = "articleRecommendation-module--title-xl--a5deb";
export var titleXs = "articleRecommendation-module--title-xs--ccd25";
export var titleXxs = "articleRecommendation-module--title-xxs--f4d55";
export var uc = "articleRecommendation-module--uc--39da8";
export var underline = "articleRecommendation-module--underline--370be";