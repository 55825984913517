import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import RichTextContentful from "../../../components/RichTextContentful";
import ALCustomLandingPage from "../../../components/al_components/ALCustomLandingPage";
import { Link } from "../../../components/al_components/ALLink";
import Breadcrumb from "../../../components/Breadcrumb";
import { edgesToArray, extractPlainText } from "../../../context/helpers";
import ArticleRecommendation from "../../../components/blog/ArticleRecommendation";

import * as blogArticleStyles from "../../../components/blog/article.module.scss";
import CaptureEmail from "../../../components/CaptureEmail/captureEmail";

function BlogArticle({ pageContext, data }) {
  const { blocks, summary, title, handle, image, createdAt, updatedAt } = pageContext?.data || {};
  const relatedCategoryArticles = edgesToArray(data?.relatedCategoryArticles || {});
  const relatedTopicArticles = edgesToArray(data.relatedTopicArticles || {});
  const latestArticles = edgesToArray(data?.latestArticles || {});
  const filters = edgesToArray(data?.blogFilters || {})[0]?.filters || [];

  let articlJsonLd = null;
  try {
    articlJsonLd = {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `https://analuisa.com/blog/${handle}/`,
      },
      headline: title,
      description: extractPlainText(summary.raw), // TODO: Add description
      datePublished: createdAt,
      dateModified: updatedAt,
      author: [
        {
          "@type": "Person",
          name: "Olivia Bennett",
          url: "https://analuisa.com/authors/olivia-bennett/",
        },
      ],
      publisher: {
        "@type": "Organization",
        name: "Ana Luisa",
        url: "https://analuisa.com",
        logo: {
          "@type": "ImageObject",
          url: "https://cdn.shopify.com/s/files/1/2579/7674/t/109/assets/logo-analuisa_400x.png?v=7594451723092916653",
          width: 400,
          height: 73,
        },
      },
    };

    if (image.altText !== "empty" && image.mobileImage.url) {
      articlJsonLd.image = [image.mobileImage.url];
    }
  } catch (error) {
    console.error("Error while creating Article JSON-LD", error);
  }

  return (
    <>
      {articlJsonLd ? (
        <Helmet>
          <link rel="canonical" href={`https://www.analuisa.com/blog/${handle}/`} />
          <meta property="og:url" content={`https://www.analuisa.com/blog/${handle}/`} />
          <script type="application/ld+json">{JSON.stringify(articlJsonLd)}</script>
        </Helmet>
      ) : null}
      <div className={blogArticleStyles.article_header}>
        <Breadcrumb
          levels={[
            { link: { link: "/blog/" }, title: "Blog" },
            { link: `/blog/${handle}/`, title },
          ]}
        />
        <h1 className="h1 h1--bold">{title}</h1>
        {image.altText !== "empty" && (
          <>
            <div
              className={`${blogArticleStyles.article_image} ${blogArticleStyles.article_imageMobile}`}
            >
              <GatsbyImage
                fetchPriority="high"
                image={image.mobileImage.gatsbyImageData}
                alt={image.altText}
                className={blogArticleStyles.article_image__gatsby}
              />
            </div>
            <div
              className={`${blogArticleStyles.article_image} ${blogArticleStyles.article_imageDesktop}`}
            >
              <GatsbyImage
                fetchPriority="high"
                image={image.desktopImage.gatsbyImageData}
                alt={image.altText}
                className={blogArticleStyles.article_image__gatsby}
              />
            </div>
          </>
        )}
      </div>

      <div className={blogArticleStyles.article_container}>
        <div className={blogArticleStyles.article_container_content}>
          <h4 className={`h2 h2--bold ${blogArticleStyles.article_container_content_table_title}`}>
            Table of content:
          </h4>
          <ul className={blogArticleStyles.article_container_content_table_content}>
            {blocks.map((block, i) => {
              console.log(block, " => block");
              if (block.title) {
                return (
                  <li key={`block_${i}`}>
                    <a
                      href={`#${block?.title
                        .toLowerCase()
                        .replace(/[^a-z0-9]+/g, "-")
                        .replace(/^-+|-+$/g, "")}`}
                    >
                      {block.title}
                    </a>
                  </li>
                );
              }
              return null;
            })}
          </ul>
          <h4
            className={`h4 h4--bold ${blogArticleStyles.article_container_content_summary_title}`}
          >
            Article Summary
          </h4>
          <RichTextContentful input={summary?.raw} />

          <ALCustomLandingPage
            className={blogArticleStyles.article_container_content_lp}
            data={{ blocks }}
            componentType="blog_article"
            showAnchor
            showTitleLpText
            showSEOLinks
          />
        </div>

        <div className={blogArticleStyles.article_container_recommendations}>
          {relatedTopicArticles.map((article) => (
            <ArticleRecommendation key={`relatedTopicArticle_${article.id}`} article={article} />
          ))}

          <div className={blogArticleStyles.article_container_recommendations_email}>
            <CaptureEmail exponeaEventName="blog_article" bloutoutEventName="Subscribe Blog" />
          </div>

          {latestArticles.map((article) => (
            <ArticleRecommendation key={`latestArticle_${article.id}`} article={article} />
          ))}
        </div>
      </div>

      <div className={blogArticleStyles.article_footer}>
        <h4 className={`h2 ${blogArticleStyles.article_footer_title}`}>
          Discover our Ultimate Guides to Style your Look
        </h4>
        <div className={blogArticleStyles.article_footer_articles}>
          {relatedCategoryArticles.map((article) => (
            <ArticleRecommendation key={`relatedCategoryArticle_${article.id}`} article={article} />
          ))}
        </div>
      </div>

      <div className={blogArticleStyles.article_filters}>
        <h4 className={`h2 ${blogArticleStyles.article_footer_title}`}>
          Explore more jewelry articles
        </h4>
        <div className={blogArticleStyles.article_filters_container}>
          {filters.map((filter) => (
            <Link
              className={`text ${blogArticleStyles.article_filter}`}
              key={`filter_${filter.title.toLowerCase()}`}
              to={filter.link.link}
            >
              {filter.title}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default withALErrorBoundary({
  name: "BlogArticle",
  priority: "P1",
})(BlogArticle);

export const query = graphql`
  query ($category: [String], $topic: [String], $handle: String) {
    latestArticles: allContentfulBlogArticle(
      sort: { order: DESC, fields: createdAt }
      filter: { node_locale: { eq: "en-US" }, handle: { ne: $handle } }
      limit: 3
    ) {
      edges {
        node {
          id
          handle
          title
          category
          subCategory
          topic
          image {
            altText
            desktopImage {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
            }
            mobileImage {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
            }
          }
          summary {
            raw
          }
        }
      }
    }
    relatedCategoryArticles: allContentfulBlogArticle(
      sort: { order: DESC, fields: updatedAt }
      filter: { node_locale: { eq: "en-US" }, category: { in: $category }, handle: { ne: $handle } }
      limit: 4
    ) {
      edges {
        node {
          id
          handle
          title
          category
          subCategory
          topic
          image {
            altText
            desktopImage {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
            }
            mobileImage {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
            }
          }
          summary {
            raw
          }
        }
      }
    }
    relatedTopicArticles: allContentfulBlogArticle(
      sort: { order: DESC, fields: updatedAt }
      filter: { node_locale: { eq: "en-US" }, topic: { in: $topic }, handle: { ne: $handle } }
      limit: 3
    ) {
      edges {
        node {
          id
          handle
          title
          category
          subCategory
          topic
          image {
            altText
            desktopImage {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
            }
            mobileImage {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
            }
          }
          summary {
            raw
          }
        }
      }
    }
    blogFilters: allContentfulBlog(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          filters {
            title
            link {
              link
            }
            buttonTextColor
            buttonBackgroundColor
          }
        }
      }
    }
  }
`;
